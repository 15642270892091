<template>
  <!-- <main-wrapper manage :style="{overflowY:isAnimating?'hidden':'auto'}" :tool="false"> -->
    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <div class="search-bars">
            <tool-tip :content="$t('notify.tip1')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="addHandle"  type="primary" plain circle v-ripple>
               <i-icon name="iconxinxiguanli_tianjia_weixuanzhong"></i-icon>
            </el-button>
            </tool-tip>
            <tool-tip :content="$t('notify.tip2')" placement="top" type="light-tip" :offset="-10">
            <el-button @click="deleteHandle"  type="primary" plain circle v-ripple>
              <i-icon name="iconshanchu"></i-icon>
            </el-button>
            </tool-tip>
            <search-group style="float:right;" :options="options" @click="click" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op} "
              :field="{select: searchName, value: searchText}"></search-group>
          </div>

          <el-table :data="tableData" class="v-table" border size="mini" :height="tableHeight" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column type="selection" width="55" :selectable="selectableHandle"></el-table-column>
            <el-table-column prop="Head" :label="$t('notify.title')" width="110" show-overflow-tooltip>
              <p slot-scope="{row}" class="i-link" @click="view(row)">{{row.Head||'--'}}</p>
            </el-table-column>
            <el-table-column :label="$t('notify.BeginTime')" width="350" show-overflow-tooltip>
              <p slot-scope="{row}" :title="`NID:${row.NID}`">{{miment(row.BeginTime).format()}} ~ {{miment(row.EndTime).format()}}
                <el-tag type="danger" v-if="row.IsDeleted">{{$t('notify.verson1')}} </el-tag>
                <el-tag type="success" v-else-if="row.NID == currentNID">{{$t('notify.verson2')}}</el-tag>
                <el-tag type="success" v-else-if="miment(row.BeginTime).getTime() > miment().getTime()">{{$t('notify.verson3')}}</el-tag>
                <el-tag type="info" v-else>{{$t('notify.verson4')}}</el-tag>
              </p>
            </el-table-column>
            <el-table-column :label="$t('notify.ShowLevel')" width="140">
              <template slot-scope="{row}">
                <p v-if="row.OnlyCompany">{{$t('notify.levelTips')}} {{row.ShowLevel}}</p>
                <p v-else>--</p>
              </template>
            </el-table-column>
            <el-table-column :label="$t('notify.UpdateTime')" width="150">
              <template slot-scope="{row}">{{miment(row.UpdateTime).format()|emptyFormat}}</template>
            </el-table-column>
            <el-table-column prop="CreateTime" :label="$t('notify.CreateTime')" width="150">
              <p slot-scope="{row}">
                {{miment(row.CreateTime).format()}}
              </p>
            </el-table-column>
            <el-table-column prop="UserName" :label="$t('notify.UserName')" width="100" show-overflow-tooltip></el-table-column>
            <el-table-column label="">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('notify.operation')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>  
                  <i-drop-down-option :label="$t('notify.preview')" value="0" @click="view(row)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('notify.delete')" value="4" @click="deleteHandle([row])"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            class="v-pager"
            background
            :page-size="pageSize"
            :pager-count="11"
            :current-page.sync="pageIndex"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount"
            @current-change="pageCurrentChange"
            @size-change="pageSizeChange"
            popper-class="custom">
          </el-pagination>

          <transition name="slide-top" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition>

          <dialog-notify ref="dialogNotify"></dialog-notify>
          <detail-notify ref="detail" @refresh="refreshHandle"></detail-notify>
          <!-- <div class="page__detail">
            测试页面
          </div> -->
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from '@/views/manage/components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from '@/views/manage/mixin'
import * as API from '@/api/notify'
import {Filter, Op} from '@/api/config'
import DetailNotify from '@/views/notify/detail-notify'
import miment from 'miment'
import DialogNotify from './dialog-notify'

export default {
  name: 'manageNotify',
  data() {
    return {
      miment,
      currentNID: null, // 当前正在使用的公告
      options: [
        { label: this.$t('notify.title'), value: 'Head', Op: Op.Contains },
        { label: this.$t('notify.UserName'), value: 'UserName ', Op: Op.Contains }
      ],
      isAnimating: false // 是否正在动画
    }
  },
  components: {
    IDropDown,
    IDropDownOption,
    SearchGroup,
    VclCode,
    DetailNotify,
    DialogNotify
  },
  mixins: [
    mixin
  ],
  created() {
    this.searchName = this.options[0].value
    this.queryHandle()
  },
  methods: {
    click() {
      this.queryHandle()
    },
    rowClick(row) {
      //this.$router.push(`/manage/user/detail?id=${row.UserID}`)
      this.$refs.detail.open(row)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      // this.$router.push('/manage/user/detail')
      this.$refs.detail.open()
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }
      const targetRows = rows || this.$refs.multipleTable.selection

      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.filter(k => !k.IsDeleted).map(k => k.NID)).then(() => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          this.$message({
            type: 'success',
            message: '删除成功',
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }

          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText && this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters).then(ret => {
          //console.log(ret)
          this.tableData = ret.data.list.slice()
          this.pageTotalCount = ret.data.total
          this.tableLoading = false

          const current = JSON.parse(ret.data.current)
          if (current.list.length > 0) {
            this.currentNID = current.list[0].NID
          }

          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    exportHandle() {
      // this.exportButtonLoading = true
      // API.Export(this.currentHoldId, this.hasChild).then(ret => {
      //   this.exportButtonLoading = false
      //   saveBlob(ret.data, '账号详情.xls')
      // })
    },
    refreshHandle() {
      this.queryHandle()
    },
    view(row) {
      this.$refs.dialogNotify.view(row)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 8px;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
  }
}
</style>
